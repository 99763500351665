.container .testimonials__container {
  width: 40%;
  padding-bottom: 4rem;
}

.client__avatar {
  width: 10rem;
  aspect-ratio: 1/1;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto 1rem;
  border: 0.4rem solid var(--color-primary-variance);
}

.client__avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.testimonial {
  background: var(--color-bg-variance);
  text-align: center;
  padding: 2rem;
  border-radius: 2rem;
  user-select: none;
}

.testimonial h6 {
  font-size: 1.2rem;
  color: var(--color-primary);
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.client__name {
  font-size: 1.8rem;
}

.client__review {
  font-size: 1.3rem;
  color: var(--color-light);
  font-weight: 300;
  display: block;
  width: 80%;
  margin: 0.8rem auto 0;
}

/* ---------- MEDIA QUERIES (MEDIUM DEVICES) ---------- */
@media screen and (max-width: 1024px) {
  .container .testimonials__container {
    width: 60%;
  }

  .testimonial h6 {
    font-size: 1.2rem;
  }

  .client__name {
    font-size: 1.5rem;
  }

  .client__review {
    font-size: 1.1rem;
    width: var(--container-width-sm);
  }
}

/* ---------- MEDIA QUERIES (SMALL DEVICES) ---------- */
@media screen and (max-width: 600px) {
  .container .testimonials__container {
    width: var(--container-width-sm);
  }

  .testimonial h6 {
    font-size: 1rem;
  }

  .client__name {
    font-size: 1.2rem;
  }

  .client__review {
    font-size: 1rem;
    width: var(--container-width-sm);
  }
}
