@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;500;600&display=swap");

* {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	box-sizing: border-box;
	list-style: none;
	text-decoration: none;
}

:root {
	--color-bg: #101819;
	--color-bg-variance: #03120e;
	--color-primary: #f72c25;
	--color-primary-variance: #036348;
	--color-white: #efd6ac;
	--color-light: #06d6a0;

	--transition: all 400ms ease;
	--swiper-navigation-color: var(--color-primary);
	--swiper-pagination-color: var(--color-primary);
	--swiper-pagination-bullet-inactive-color: var(--color-primary-variance);

	/* assign width for all elements that have containers */
	--container-width-lg: 73%;
	--container-width-md: 86%;
	--container-width-sm: 90%;
}

/* ensure smooth scrolling accross website */
html {
	scroll-behavior: smooth;
}

body {
	font-family: "Kanit", sans-serif;
	background: var(--color-bg);
	color: var(--color-white);
	line-height: 1.7;
	background-image: url(../src/assets/bg-texture.png);
}

/* do not display the scrollbar */
::-webkit-scrollbar {
	display: none;
}

/* ------------ GENERAL STYLES ------------ */
.container {
	width: var(--container-width-lg);
	margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
	font-weight: 500;
}

h1 {
	font-size: 2.5rem;
}

section {
	margin-top: 8rem;
}

/* allowing for child tags to be customised */
section > h2,
section > h5 {
	text-align: center;
	color: var(--color-light);
}

section > h2 {
	color: var(--color-primary);
	margin-bottom: 3rem;
}

.text-light {
	color: var(--color-light);
}

a {
	color: var(--color-primary);
	transition: var(--transition);
}

a:hover {
	color: var(--color-white);
}

/* allow buttons to be optimsed depending on contents */
.btn {
	width: max-content;
	display: inline-block;
	color: var(--color-primary);
	padding: 0.75rem 1.2rem;
	border-radius: 0.4rem;
	cursor: pointer;
	border: 1px solid var(--color-primary);
	transition: var(--transition);
}

.btn:hover {
	background: var(--color-white);
	color: var(--color-bg);
	border-color: transparent;
}

.btn-primary {
	background: var(--color-primary);
	color: var(--color-bg);
}

img {
	display: block;
	width: 100%;
	object-fit: cover;
}

/* ---------- MEDIA QUERIES (Medium Devices) ---------- */
@media screen and (max-width: 1024px) {
	.container {
		width: var(--container-width-md);
	}

	section {
		margin-top: 6rem;
	}
}

/* ---------- MEDIA QUERIES (Small Devices) ---------- */
@media screen and (max-width: 600px) {
	.container {
		width: var(--container-width-sm);
	}

	section > h2 {
		margin-top: 2rem;
	}
}
