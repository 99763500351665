header {
  height: 100vh;
  padding-top: 2rem;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

.header__container h5 {
  font-size: 1.2rem;
}

/* ---------- CTA ---------- */
.cta {
  margin-top: 1rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/* ---------- SOCIALS ---------- */
.header__socials-pro {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  right: 0;
  bottom: 5rem;
}

.header__socials-pro::after {
  content: "";
  width: 1px;
  height: 5rem;
  background-color: var(--color-primary);
}

/* ---------- IMAGE ---------- */
.me {
  background: linear-gradient(var(--color-primary), transparent);
  width: 22rem;
  height: 30rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 4rem;
  border-radius: 12rem 12rem 0 0;
  overflow: visible;
  /* padding: 0rem 0rem 0rem 0rem; */
}

.me img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* ---------- SCROLL ---------- *

/* ---------- MEDIA QUERIES (MEDIUM DEVICES) ---------- */
@media screen and (max-width: 1024px) {
  header {
    height: 95vh;
  }
}

/* ---------- MEDIA QUERIES (SMALL DEVICES) ---------- */
@media screen and (max-width: 600px) {
  header {
    height: 90vh;
  }

  .me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 20rem;
    height: 28rem;
    position: absolute;
    left: calc(50% - 10rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: visible;
  }

  .scroll__down {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  header {
    height: 70vh;
  }

  .me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 15rem;
    height: 20rem;
    position: absolute;
    left: calc(50% - 7.5rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: visible;
    /* padding: 0rem 0rem 0rem 0rem; */
  }

  .scroll__down {
    display: none;
  }
}
