/* styling for the overall nav placed on the screen */
nav {
	background: var(--color-bg);
	box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
	display: block;
	padding: 0.5rem, 1.5rem;
	z-index: 2;
	position: fixed;
	left: 50%;
	transform: translateX(-50%);
	bottom: 1.5rem;
	display: flex;
	gap: 0.3rem;
	border-radius: 3rem;
	backdrop-filter: blur(15px);
}

/* styling for icons placed on the nav */
nav a {
	background: transparent;
	padding: 0.6rem;
	border-radius: 50%;
	display: flex;
	color: var(--color-light);
	font-size: 1rem;
}

nav a:hover {
	background: var(--color-primary);
}

nav a.active {
	background: var(--color-primary-variance);
	color: var(--color-primary);
}

/* ---------- MEDIA QUERIES (SMALL DEVICES) ---------- */
@media screen and (max-width: 600px) {
	nav {
		position: fixed;
		padding: 0.3rem 0.5rem;
		gap: 0.5rem;
	}

	nav a {
		padding: 0.5rem;
		font-size: 0.8rem;
	}
}
